<router>
{
    name: 'Sales Tool System Tags',
}
</router>
<template lang="pug">
v-container(fluid)
    v-row
        v-col
            systemTags

    //- v-btn(primary, @click="$auth.logout('aad')") Sign out
</template>
<script>

import systemTags from '@/components/salestool/systemTags.vue';
export default {
    meta: {
        role: 'sat'
    },
    components: {
        systemTags
    },
    data () {
        return {
            cards: [
                { title: 'Pre-fab homes', src: 'https://cdn.vuetifyjs.com/images/cards/house.jpg', flex: 12 },
                { title: 'Favorite road trips', src: 'https://cdn.vuetifyjs.com/images/cards/road.jpg', flex: 6 },
                { title: 'Best airlines', src: 'https://cdn.vuetifyjs.com/images/cards/plane.jpg', flex: 6 },
            ],
        };
    },
    methods: {

    },
    computed: {

    },
    mounted () {
    }
};
</script>

<style scoped>
</style>
